<template>
  <b-modal
    id="modal-show-group-booking-created"
    title="Thông tin mã đặt chỗ"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    no-close-on-backdrop
    body-class="my-1"
    size="lg"
    @show="showHandle"
  >
    <template #modal-footer="{ cancel }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        size="md"
        variant="outline-secondary"
        class="center rounded-pill"
        @click="cancel()"
      >
        {{ $t('flight.Back') }}
      </b-button>

      <!-- <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="btn-gradient border-right-0"
        pill
        :disabled="!payments"
        @click="okHandle"
      >
        <div class="d-flex-center px-25">
          Giữ chỗ
        </div>
      </b-button> -->
    </template>

    <b-overlay
      :show="isEmpty(listBookings)"
      rounded="sm"
      no-fade
      class="my-2"
      spinner-variant="primary"
      variant="transparent"
      opacity="0.85"
    >
      <b-card
        header-bg-variant="light-success"
        header-class="py-1"
        no-body
      >
        <template #header>
          <span class="text-airline fw-700">Đặt vé thành công!</span>
        </template>
        <div
          v-for="(booking, indexBooking) of listBookings"
          :key="indexBooking"
        >
          <b-card class="mb-75">
            <div class="font-weight-bolder mb-50">
              Mã đặt chỗ #{{ indexBooking + 1 }}:
              <b-button
                class="px-1 py-50 py-lg-75 rounded-lg text-airline fw-700 font-medium-4"
                variant="outline-info"
                @click="handleGoToReservation(booking.resBooking)"
              >
                {{ booking.resBooking.pnr }}
              </b-button>
            </div>
            <div class="font-weight-bolder mb-50">
              Hành khách:
              <div
                v-for="(pax, indexPax) of booking.resBooking.paxLists"
                :key="indexPax"
              >
                {{ indexPax + 1 }}: {{ pax.lastName }} {{ pax.firstName }}

              </div>
            </div>
          </b-card>
        </div>
      </b-card>

      <b-card
        v-if="!isEmpty(listFailedPax)"
        header-bg-variant="light-danger"
        header-class="py-1"
        no-body
      >
        <template #header>
          <div>
            Hành khách đặt chỗ không thành công:
          </div>
        </template>

        <div
          v-for="(pax, indexPax) of listFailedPax"
          :key="indexPax"
          class="mb-75"
        >
          {{ indexPax + 1 }}. {{ listFailedPax.firstName }} {{ listFailedPax.lastName }}
        </div>
      </b-card>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BModal,
  BOverlay,
  BButton,
  BCard,
  // BTable,
  // BCol,
} from 'bootstrap-vue'
import {
  ref, computed, toRefs, watch,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'

import { useRouter } from '@/@core/utils/utils'
import { formatCurrency, convertISODateTime } from '@/@core/utils/filter'
// import store from '@/store'
// import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    BModal,
    BCard,
    BOverlay,
    BButton,
    // BTable,
    // BCol,
  },
  props: {
    dataGroupBooking: {
      type: [Object, null],
      default: () => null,
    },
  },
  setup(props) {
    const { router } = useRouter()

    // const loading = ref(true)
    const { dataGroupBooking } = toRefs(props)

    const listBookings = computed(() => {
      if (isEmpty(dataGroupBooking.value)) {
        return []
      }
      // loading.value = false
      return dataGroupBooking.value?.bookings
    })

    const listFailedPax = computed(() => {
      if (isEmpty(dataGroupBooking.value)) {
        return []
      }
      // loading.value = false
      return dataGroupBooking.value?.paxFailed
    })

    function showHandle() {
      // loading.value = true
    }

    const handleGoToReservation = resBooking => {
      router.push({
        name: 'apps-reservations-modify',
        params: { id: resBooking.bookingId },
      })
    }

    return {
      // loading,
      isEmpty,
      showHandle,
      formatCurrency,
      convertISODateTime,
      handleGoToReservation,
      listBookings,
      listFailedPax,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
